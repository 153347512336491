import { React, dtos, serviceClient, observable, observer, ui, moment, shared, selectFilter, makeObservable } from "../common";
import { ServerEventsClient } from "@servicestack/client";
import AutoComplete from "../autocomplete";

interface Props {
    accountId: string;
}

@observer
export default class Reports extends React.Component<Props> {
    @observable selectedReport = localStorage.getItem("reports.selected") ||  (shared.roles.indexOf("CustomerSimplified") >= 0 ? "cdr" : "billing");
    @observable timezones: Array<dtos.SystemTimeZoneInfo> = [];

    @observable endpointsReport: dtos.EndpointsReport;
    @observable billingReport: dtos.BillingReport;
    @observable callCenterReport: dtos.CallCenterReport;
    @observable callCenterDetailsReport: dtos.CallCenterDetailReport;
    @observable syncReport: dtos.SyncPhoneNumbersReport;
    @observable cdrReport: dtos.CdrReport;
    @observable agentStateReport: dtos.AgentStateReport;
    @observable abandonReport: dtos.CallCenterAbandonReport;
    @observable metricReport: dtos.CustomMetricReport;
    @observable callOutcomeReport: dtos.CallOutcomeReport;

    @observable emailAddressToNotify = "";
    @observable reports: dtos.ReportInfo[] = [];
    dropdownOpen = false;

    pollTimer = null;

    constructor(props: Props) {
        super(props);
        makeObservable(this);
        this.init(props.accountId);
        this.emailAddressToNotify = localStorage.getItem("ReportIndex.emailAddressToNotify") || "";
    }

    async componentDidMount() {
        this.timezones = await serviceClient.get(new dtos.ListSystemTimeZones());
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.accountId != prevProps.accountId) {
            this.init(this.props.accountId);
        }
    }

    componentWillUnmount() {
        if (this.pollTimer != null) {
            clearInterval(this.pollTimer);
            this.pollTimer = null;
        }
    }

    init(accountId: string) {
        const timeZoneId = localStorage.getItem("reports.timeZoneId") || null;

        this.endpointsReport = new dtos.EndpointsReport({ accountId: accountId, types: [] });
        this.billingReport = new dtos.BillingReport({
            accountId: accountId,
            startDate: moment().startOf("month").format("MM/DD/YY"),
            endDate: moment().endOf("month").format("MM/DD/YY")
        });
        this.callCenterReport = new dtos.CallCenterReport({
            accountId: accountId,
            startDate: moment().startOf("month").toISOString(),
            endDate: moment().endOf("month").toISOString()
        });
        this.callCenterDetailsReport = new dtos.CallCenterDetailReport({
            accountId: accountId,
            startDate: moment().startOf("month").toISOString(),
            endDate: moment().endOf("month").toISOString(),
            timeZoneId: timeZoneId
        });
        this.callOutcomeReport = new dtos.CallOutcomeReport({
            accountId: accountId,
            startDate: moment().startOf("month").toISOString(),
            endDate: moment().endOf("month").toISOString(),
            timeZoneId: timeZoneId,
            customerId: "",
            specificState: ""
        })
        this.cdrReport = new dtos.CdrReport({
            accountId: accountId,
            startDate: moment().startOf("month").toISOString(),
            endDate: moment().endOf("month").toISOString(),
            customerId: ""
        });
        this.agentStateReport = new dtos.AgentStateReport({
            accountId: accountId,
            startDate: moment().startOf("day").toISOString(),
            endDate: moment().endOf("day").toISOString(),
            timeZoneId: timeZoneId
        });
        this.abandonReport = new dtos.CallCenterAbandonReport({
            accountId: accountId,
            startDate: moment().startOf("month").toISOString(),
            endDate: moment().endOf("month").toISOString(),
            timeZoneId: timeZoneId
        });
        this.metricReport = new dtos.CustomMetricReport({
            accountId: accountId,
            startDate: moment().startOf("month").toISOString(),
            endDate: moment().endOf("month").toISOString(),
            metricName: "Missed Call",
            timeZoneId: timeZoneId
        });
        this.syncReport = new dtos.SyncPhoneNumbersReport({
            accountId: accountId
        })

        this.pollTimer = setInterval(() => this.refresh(), 2500);
        this.refresh();
    }

    async refresh() {
        if (this.dropdownOpen) return;
        let response = await serviceClient.get(new dtos.ListReports({ accountId: shared.accountId }));
        this.reports = response.reports;
    }

    getReport(): dtos.IReturn<dtos.ReportInfo> {
        switch (this.selectedReport) {
            case "endpoints":
                return this.endpointsReport;
            case "billing":
                return this.billingReport;
            case "callcenter":
                return this.callCenterReport;
            case "callcenter-details":
                return this.callCenterDetailsReport;
            case "sync":
                return this.syncReport;
            case "cdr":
                return this.cdrReport;
            case "agentState":
                return this.agentStateReport;
            case "abandon":
                return this.abandonReport;
            case "metric":
                return this.metricReport;
            case "call-outcome":
                return this.callOutcomeReport;
            default:
                return null;
        }
    }

    async generate() {
        let report = this.getReport();
        if (Object.hasOwn(report, "timeZoneId")) {
            localStorage.setItem("reports.timeZoneId", (report as any).timeZoneId);
        }
        
        (report as any).emailAddressToNotify = this.emailAddressToNotify
        if (report == null) {
            ui.message.error("Could not find that report");
            return;
        }
        localStorage.setItem("ReportIndex.emailAddressToNotify", this.emailAddressToNotify);
        let response = await serviceClient.post(report);
        this.reports.push(response);
        this.forceUpdate();
    }

    selectReport(rpt) {
        this.selectedReport = rpt;
        localStorage.setItem("reports.selected", rpt);
    }

    setDateRange(rpt: any, range) {
        rpt.startDate = range[0].format("YYYY-MM-DD");
        rpt.endDate = range[1].format("YYYY-MM-DD");
        this.forceUpdate();
    }

    async deleteReport(rpt: dtos.ReportInfo) {
        await serviceClient.delete(new dtos.DeleteReport({ reportId: rpt.id }));
        let idx = this.reports.indexOf(rpt);
        if (idx >= 0) {
            this.reports.splice(idx, 1);
            this.forceUpdate();
        }
    }

    async clearAll() {
        for (var i = this.reports.length - 1; i >= 0; i--) {
            await this.deleteReport(this.reports[i]);
        }
    }

    onOpenChange(open: boolean) {
        this.dropdownOpen = open;
    }

    render() {
        const simplified = shared.roles.indexOf("CustomerSimplified") >= 0;

        return (
            <div>
                <ui.Row gutter={16}>
                    <ui.Col span={6}>
                        <ui.Form layout="vertical">
                            <ui.Form.Item label="Report">
                                <ui.Select value={this.selectedReport} onChange={ev => this.selectReport(ev)}>
                                    {!simplified && <ui.Select.Option value="billing">Billing Report</ui.Select.Option>}
                                    {!simplified && <ui.Select.Option value="endpoints">Endpoints Report</ui.Select.Option>}
                                    <ui.Select.Option value="cdr">CDR Report</ui.Select.Option>
                                    {!shared.isCustomer() && <ui.Select.Option value="callcenter">Call Center Report</ui.Select.Option>}
                                    {!shared.isCustomer() && <ui.Select.Option value="callcenter-details">Call Center Details Report</ui.Select.Option>}
                                    {!shared.isCustomer() && <ui.Select.Option value="agentState">Agent State Report</ui.Select.Option>}
                                    {!shared.isCustomer() && <ui.Select.Option value="abandon">Abandoned Calls Report</ui.Select.Option>}
                                    <ui.Select.Option value="call-outcome">Call Outcome Report</ui.Select.Option>
                                    {!shared.isCustomer() && <ui.Select.Option value="metric">Custom Metrics Report</ui.Select.Option>}
                                    {!shared.isCustomer() && <ui.Select.Option value="sync">Sync Phone Numbers Report</ui.Select.Option>}
                                </ui.Select>
                            </ui.Form.Item>

                            {this.selectedReport == "sync" && <React.Fragment>
                                <ui.Checkbox style={{ marginBottom: 16 }} checked={this.syncReport.syncDocumoNumbers} onChange={ev => { this.syncReport.syncDocumoNumbers = ev.target.checked; this.forceUpdate(); }}>Sync Documo Fax Numbers</ui.Checkbox>
                            </React.Fragment>}

                            {this.selectedReport == "endpoints" && <React.Fragment>
                                <ui.Form.Item label="Customer">
                                    <AutoComplete placeholder="Filter by customer" type={dtos.ValueTypes.Customer} value={this.endpointsReport.customerId} onChanged={c => this.endpointsReport.customerId = c} />
                                </ui.Form.Item>
                            </React.Fragment>}

                            {this.selectedReport == "billing" && <React.Fragment>
                                <ui.Form.Item label="Date Range">
                                    <ui.DatePicker.RangePicker onOpenChange={this.onOpenChange.bind(this)} value={[moment(this.billingReport.startDate), moment(this.billingReport.endDate)]} onChange={ev => this.setDateRange(this.billingReport, ev)} />
                                </ui.Form.Item>
                            </React.Fragment>}


                            {this.selectedReport == "agentState" && <React.Fragment>
                                <ui.Form.Item label="Date Range">
                                    <ui.DatePicker.RangePicker onOpenChange={this.onOpenChange.bind(this)} value={[moment(this.agentStateReport.startDate), moment(this.agentStateReport.endDate)]} onChange={ev => this.setDateRange(this.agentStateReport, ev)} />
                                </ui.Form.Item>
                                <ui.Form.Item label="Time Zone">
                                    <ui.Select showSearch filterOption={selectFilter} placeholder="Specify your time zone" value={this.agentStateReport.timeZoneId} onChange={v => { this.agentStateReport.timeZoneId = v; this.forceUpdate() }} dropdownMatchSelectWidth={false}>
                                        {this.timezones.map(tz => <ui.Select.Option key={tz.id} value={tz.id}>{tz.displayName}</ui.Select.Option>)}
                                    </ui.Select>
                                </ui.Form.Item>
                            </React.Fragment>}

                            {this.selectedReport == "abandon" && <React.Fragment>
                                <ui.Form.Item label="Date Range">
                                    <ui.DatePicker.RangePicker onOpenChange={this.onOpenChange.bind(this)} value={[moment(this.abandonReport.startDate), moment(this.abandonReport.endDate)]} onChange={ev => this.setDateRange(this.abandonReport, ev)} />
                                </ui.Form.Item>
                                <ui.Form.Item label="Time Zone">
                                    <ui.Select showSearch filterOption={selectFilter} placeholder="Specify your time zone" value={this.abandonReport.timeZoneId} onChange={v => { this.abandonReport.timeZoneId = v; this.forceUpdate() }} dropdownMatchSelectWidth={false}>
                                        {this.timezones.map(tz => <ui.Select.Option key={tz.id} value={tz.id}>{tz.displayName}</ui.Select.Option>)}
                                    </ui.Select>
                                </ui.Form.Item>
                            </React.Fragment>}

                            {this.selectedReport == "metric" && <React.Fragment>
                                <ui.Form.Item label="Metric">
                                    <ui.AutoComplete placeholder="Select a Metric" value={this.metricReport.metricName} onChange={v => { this.metricReport.metricName = v.toString(); this.forceUpdate(); }}>
                                        <ui.AutoComplete.Option value="Missed Call">Missed Call</ui.AutoComplete.Option>
                                    </ui.AutoComplete>
                                </ui.Form.Item>
                                <ui.Form.Item label="Date Range">
                                    <ui.DatePicker.RangePicker onOpenChange={this.onOpenChange.bind(this)} value={[moment(this.metricReport.startDate), moment(this.metricReport.endDate)]} onChange={ev => this.setDateRange(this.metricReport, ev)} />
                                </ui.Form.Item>
                                <ui.Form.Item label="Time Zone">
                                    <ui.Select showSearch filterOption={selectFilter} placeholder="Specify your time zone" value={this.metricReport.timeZoneId} onChange={v => { this.metricReport.timeZoneId = v; this.forceUpdate() }} dropdownMatchSelectWidth={false}>
                                        {this.timezones.map(tz => <ui.Select.Option key={tz.id} value={tz.id}>{tz.displayName}</ui.Select.Option>)}
                                    </ui.Select>
                                </ui.Form.Item>
                            </React.Fragment>}

                            {this.selectedReport == "cdr" && <React.Fragment>
                                <ui.Form.Item label="Time Zone">
                                    <ui.Select showSearch filterOption={selectFilter} placeholder="Specify your time zone" value={this.cdrReport.timeZoneId} onChange={v => { this.cdrReport.timeZoneId = v; this.forceUpdate() }} dropdownMatchSelectWidth={false}>
                                        {this.timezones.map(tz => <ui.Select.Option key={tz.id} value={tz.id}>{tz.displayName}</ui.Select.Option>)}
                                    </ui.Select>
                                </ui.Form.Item>
                                {!simplified && <ui.Form.Item label="Customer">
                                    <AutoComplete placeholder="Filter by customer" type={dtos.ValueTypes.Customer} value={this.cdrReport.customerId} onChanged={c => this.cdrReport.customerId = c} />
                                </ui.Form.Item>}
                                <ui.Form.Item label="Date Range">
                                    <ui.DatePicker.RangePicker onOpenChange={this.onOpenChange.bind(this)} value={[moment(this.cdrReport.startDate), moment(this.cdrReport.endDate)]} onChange={ev => this.setDateRange(this.cdrReport, ev)} />
                                </ui.Form.Item>
                            </React.Fragment>}

                            {this.selectedReport == "call-outcome" && <React.Fragment>
                                <ui.Form.Item label="Time Zone">
                                    <ui.Select showSearch filterOption={selectFilter} placeholder="Specify your time zone" value={this.callOutcomeReport.timeZoneId} onChange={v => { this.callOutcomeReport.timeZoneId = v; this.forceUpdate() }} dropdownMatchSelectWidth={false}>
                                        {this.timezones.map(tz => <ui.Select.Option key={tz.id} value={tz.id}>{tz.displayName}</ui.Select.Option>)}
                                    </ui.Select>
                                </ui.Form.Item>
                                {!simplified && <ui.Form.Item label="Customer">
                                    <AutoComplete placeholder="Filter by customer" type={dtos.ValueTypes.Customer} value={this.callOutcomeReport.customerId} onChanged={c => this.callOutcomeReport.customerId = c} />
                                </ui.Form.Item>}
                                <ui.Form.Item label="Date Range">
                                    <ui.DatePicker.RangePicker onOpenChange={this.onOpenChange.bind(this)} value={[moment(this.callOutcomeReport.startDate), moment(this.callOutcomeReport.endDate)]} onChange={ev => this.setDateRange(this.callOutcomeReport, ev)} />
                                </ui.Form.Item>
                                <ui.Form.Item label="Specific Time of Day">
                                    <ui.Select showSearch filterOption={selectFilter} placeholder="Select a Schedule State" value={this.callOutcomeReport.specificState} onChange={v => { this.callOutcomeReport.specificState = v; this.forceUpdate() }}>
                                        <ui.Select.Option value="">(Not Specified)</ui.Select.Option>
                                        <ui.Select.Option value="Business Hours">Business Hours</ui.Select.Option>
                                        <ui.Select.Option value="After Hours">After Hours</ui.Select.Option>
                                        <ui.Select.Option value="Holiday">Holiday</ui.Select.Option>
                                    </ui.Select>
                                </ui.Form.Item>
                            </React.Fragment>}

                            {this.selectedReport == "callcenter" && <React.Fragment>
                                <ui.Form.Item label="Date Range">
                                    <ui.DatePicker.RangePicker onOpenChange={this.onOpenChange.bind(this)} value={[moment(this.callCenterReport.startDate), moment(this.callCenterReport.endDate)]} onChange={ev => this.setDateRange(this.callCenterReport, ev)} />
                                </ui.Form.Item>
                                <ui.Form.Item label="Customer">
                                    <AutoComplete placeholder="Filter by customer" type={dtos.ValueTypes.Customer} value={this.callCenterReport.customerId} onChanged={v => { this.callCenterReport.customerId = v; this.forceUpdate(); }} />
                                </ui.Form.Item>
                            </React.Fragment>}

                            {this.selectedReport == "callcenter-details" && <React.Fragment>
                                <ui.Form.Item label="Date Range">
                                    <ui.DatePicker.RangePicker onOpenChange={this.onOpenChange.bind(this)} value={[moment(this.callCenterDetailsReport.startDate), moment(this.callCenterDetailsReport.endDate)]} onChange={ev => this.setDateRange(this.callCenterDetailsReport, ev)} />
                                </ui.Form.Item>
                                <ui.Form.Item label="Time Zone">
                                    <ui.Select showSearch filterOption={selectFilter} placeholder="Specify your time zone" value={this.callCenterDetailsReport.timeZoneId} onChange={v => { this.callCenterDetailsReport.timeZoneId = v; this.forceUpdate() }} dropdownMatchSelectWidth={false}>
                                        {this.timezones.map(tz => <ui.Select.Option key={tz.id} value={tz.id}>{tz.displayName}</ui.Select.Option>)}
                                    </ui.Select>
                                </ui.Form.Item>
                                <ui.Form.Item label="Customer">
                                    <AutoComplete placeholder="Filter by customer" type={dtos.ValueTypes.Customer} value={this.callCenterDetailsReport.customerId} onChanged={v => { this.callCenterDetailsReport.customerId = v; this.forceUpdate(); }} />
                                </ui.Form.Item>
                            </React.Fragment>}
                            <ui.Form.Item label="Email Address to Notify (optional)">
                                <ui.Input type="email" value={this.emailAddressToNotify} onChange={ev => this.emailAddressToNotify = ev.target.value} />
                            </ui.Form.Item>
                            <ui.Form.Item>
                                <ui.Button type="primary" onClick={() => this.generate()}>Generate Report</ui.Button>
                                <p><small>After clicking Generate, you can leave this page and your report will continue to run</small></p>

                            </ui.Form.Item>
                        </ui.Form>
                    </ui.Col>
                    <ui.Col span={18}>
                        <ui.Table dataSource={this.reports} rowKey="id" pagination={false}>
                            <ui.Table.Column dataIndex="name" title="Name" render={(text, rec:dtos.ReportInfo) => <ui.Col><ui.Row>{rec.name}</ui.Row><ui.Row><small>{rec.server}</small></ui.Row></ui.Col>} />
                            <ui.Table.Column dataIndex="dateCreated" title="Date Created" render={(text, rec) => moment(text).format("LLLL")} />
                            <ui.Table.Column dataIndex="dateUpdated" title="Last Updated" render={(text, rec:dtos.ReportInfo) => moment(text).fromNow()} />
                            <ui.Table.Column dataIndex="status" title="Status" />
                            <ui.Table.Column dataIndex="statusMessage" title="Message" />
                            <ui.Table.Column dataIndex="emailAddressToNotify" title="Email" />
                            <ui.Table.Column dataIndex="downloadLink" title="Download" render={(text, rec) => text && <a href={text} target="_blank">Download</a>} />
                            <ui.Table.Column title="" render={(text, rec: dtos.ReportInfo) => <ui.Popconfirm title="Clear selected report?" onConfirm={() => this.deleteReport(rec)}><a>{rec.status == dtos.ReportStatuses.Completed || rec.status == dtos.ReportStatuses.Error ? "Clear" : "Cancel"}</a></ui.Popconfirm>} />
                        </ui.Table>
                        <ui.Button.Group style={{ marginTop: 8 }}>
                            <ui.Popconfirm title="Clear all reports?" onConfirm={() => this.clearAll()}><ui.Button>Clear All</ui.Button></ui.Popconfirm>
                        </ui.Button.Group>
                        <p><small>The above list shows all active and previously generated reports. Reports will be deleted automatically 30 days after they are created.</small></p>
                    </ui.Col>

                </ui.Row>
            </div>);
    }
}