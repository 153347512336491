import { React, serviceClient, dtos, observer, observable, ui, _, Link, Observer, humanFileSize, moment, splitIntoWords, selectFilter, shared, makeObservable } from "../common";
import { UploadChangeParam } from "antd/lib/upload";
import AudioRecorder from "../audio-recorder";
import { Moment } from "moment";
import AutoComplete from "../autocomplete";

interface Props {
    accountId: string;
}

@observer
export default class FilesIndex extends React.Component<Props> {
    @observable files: Array<dtos.FileInfo> = [];
    @observable pagination: any = { position: "both" };
    @observable sort: any = {};
    @observable filter: any = {};
    @observable pageSize = 20;
    @observable accountId = "";
    @observable loading = false;
    @observable saving = false;
    @observable startDate: Moment = null;
    @observable endDate: Moment = null;

    @observable showVoiceRecorder = false;
    @observable editFileId = "";
    @observable editFileName = "";
    @observable editFileCustomer = "";
    @observable editFileUser = "";
    @observable editFileAccount = "";
    @observable showUpdateFile: boolean;
    @observable selectedFileIds: Array<string> = [];
    @observable showAudioFileWindow = false;
    @observable audioFileToShow: dtos.FileInfo;
    @observable loadingAudioFile = false;
    @observable playbackOptimized = false;

    audioPlayer = React.createRef<HTMLAudioElement>();
    updateFile: dtos.FileInfo;

    constructor(props) {
        super(props);
        makeObservable(this);
        this.filter.fileName = localStorage.getItem("FilesIndex.filter.fileName");
        this.filter.type = localStorage.getItem("FilesIndex.filter.type");
        this.filter.customerId = localStorage.getItem("FilesIndex.filter.customerId");
        this.filter.userId = localStorage.getItem("FilesIndex.filter.userId");
        this.filter.transcriptionContains = localStorage.getItem("FilesIndex.filter.transcriptionContains")
        this.pageSize = parseInt(localStorage.getItem("FilesIndex.pagination.pageSize") || "20");
        var startDate = localStorage.getItem("FilesIndex.filter.startDate");
        if (startDate) {
            this.startDate = moment(startDate);
        } else {
            this.startDate = null;
        }

        var endDate = localStorage.getItem("FilesIndex.filter.endDate");
        if (endDate) {
            this.endDate = moment(endDate);
        } else {
            this.endDate = null;
        }
    }

    async componentDidMount() {
        this.accountId = this.props.accountId;
        this.refresh(1, {});
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.accountId != prevProps.accountId) {
            this.accountId = this.props.accountId;
            this.refresh();
        }
    }

    async refresh(page = this.pagination.page || 1, sort = this.sort) {
        let request = new dtos.ListFiles({
            countPerPage: this.pageSize,
            page: page - 1,
            sortField: sort.field || "dateCreated",
            sortOrder: sort.order || "descend",
            fileName: this.filter.fileName,
            userId: this.filter.userId,
            type: this.filter.type,
            customerIds: this.filter.customerId ? [this.filter.customerId] : null,
            accountIds: [this.accountId],
            dateCreatedStart: this.startDate == null ? null : this.startDate.toISOString(),
            dateCreatedEnd: this.endDate == null ? null : this.endDate.toISOString(),
            transcriptionContains: this.filter.transcriptionContains,
            simplifiedPaging: true
        });
        let response = await serviceClient.get(request);
        this.files = response.items;
        this.sort = sort;
        let pager = { ...this.pagination };
        pager.current = page;
        pager.total = page * this.pageSize + (response.hasMorePages ? 1 : 0);
        pager.pageSize = this.pageSize;
        pager.showSizeChanger = true;
        pager.pageSizeOptions = ["10", "20", "50", "100", "250"];
        pager.onShowSizeChange = (c, v) => this.pageSize = v;
        this.pagination = pager;

        localStorage.setItem("FilesIndex.filter.fileName", this.filter.fileName || "");
        localStorage.setItem("FilesIndex.filter.type", this.filter.type || "");
        localStorage.setItem("FilesIndex.filter.customerId", this.filter.customerId || "");
        localStorage.setItem("FilesIndex.filter.userId", this.filter.userId || "");
        localStorage.setItem("FilesIndex.pagination.pageSize", this.pageSize.toString());
        localStorage.setItem("FilesIndex.filter.startDate", this.startDate == null ? "" : this.startDate.toISOString());
        localStorage.setItem("FilesIndex.filter.endDate", this.endDate == null ? "" : this.endDate.toISOString());
        localStorage.setItem("FilesIndex.filter.transcriptionContains", this.filter.transcriptionContains || "");
    }

    clearSearch() {
        this.startDate = null;
        this.endDate = null;
        this.filter = {};
        this.refresh();
    }




    startEditFile(file: dtos.FileInfo) {
        this.updateFile = file;
        this.editFileId = file.id;
        this.editFileName = file.fileName;
        this.editFileAccount = file.accountId;
        this.editFileCustomer = file.customerId;
        this.editFileUser = file.userId;
        this.showUpdateFile = true;
        this.saving = false;
    }

    async deleteFiles() {
        try {
            this.loading = true;
            for (let fileId of this.selectedFileIds) {
                await serviceClient.delete(new dtos.DeleteFile({ fileId: fileId }));
            }
            this.selectedFileIds = [];
            this.refresh();
        } finally {
            this.loading = false;
        }
    }

    async saveFile() {
        let request = new dtos.PatchFile({ fileId: this.editFileId, fileName: this.editFileName, customerId: this.editFileCustomer, userId: this.editFileUser });
        let updatedFile = await serviceClient.patch(request);
        Object.assign(this.updateFile, updatedFile);
        this.showUpdateFile = false;
    }

    async deleteFile(file: dtos.FileInfo) {
        await serviceClient.delete(new dtos.DeleteFile({ fileId: file.id }));
        let idx = _.findIndex(this.files, f => f.id == file.id);
        this.files.splice(idx, 1);
        this.forceUpdate();
    }

    onUploadChange(info: UploadChangeParam) {
        if (info.file.status == "done") {
            let newFile = info.file.response;
            this.files.unshift(newFile);
            this.forceUpdate();
        }
    }

    onPatchChange(info:UploadChangeParam) {
        if (info.file.status == "done") {
            ui.notification.success({message: "File updated successfully"})
        }
    }

    openVoiceRecorder() {
        this.showVoiceRecorder = true;
    }

    onSavedFile(f: dtos.FileInfo) {
        this.files = [f, ...this.files];
        this.showVoiceRecorder = false;
    }

    checkFilterSubmit(ev: React.KeyboardEvent) {
        if (ev.which == 13) {
            this.refresh();
        }
    }

    setDateRange(range) {
        this.startDate = range[0].startOf("day");
        this.endDate = range[1].endOf("day");
    }

    async showAudioFile(rec: dtos.FileInfo) {
        this.audioFileToShow = rec;
        this.showAudioFileWindow = true;
        this.audioPlayer.current.src = "";
        this.loadingAudioFile = true;
        this.playbackOptimized = true;

        try {
            let res = await fetch(rec.uri);
            if (!res.ok) throw res.statusText;
            let blob = await res.blob();
            this.audioPlayer.current.src = URL.createObjectURL(blob);
            this.playbackOptimized = true;
        } catch (err) {
            this.audioPlayer.current.src = rec.uri;
            this.playbackOptimized = false;
        } finally {
            this.loadingAudioFile = false;
        }
    }

    render() {
        const rowSelection = {
            selectedRowKeys: this.selectedFileIds,
            onChange: keys => this.selectedFileIds = keys
        };

        return (
            <div>
                <ui.Button.Group style={{ marginBottom: 16 }}>
                    <ui.Upload showUploadList={false} name="file" data={{ accountId: this.accountId }} withCredentials={true} headers={{ "X-Requested-With": null }} action={serviceClient.createUrlFromDto("POST", new dtos.NewFile())} onChange={info => this.onUploadChange(info)}>
                        <ui.Button type="primary"><i className="fa fa-upload" />&nbsp;Click to Upload</ui.Button>
                    </ui.Upload>
                    <ui.Button onClick={() => this.openVoiceRecorder()}><i className="fa fa-microphone" />&nbsp;Record</ui.Button>
                    {this.selectedFileIds.length > 0 && <ui.Popconfirm title="Are you sure you want to delete the selected files?" onConfirm={() => this.deleteFiles()}><ui.Button danger>Delete Files</ui.Button></ui.Popconfirm>}
                </ui.Button.Group>
                <ui.Form layout="inline" style={{ marginBottom: 8 }}>
                    <ui.Form.Item>
                        <ui.Input onKeyPress={ev => this.checkFilterSubmit(ev)} placeholder="Search by file name" value={this.filter.fileName || ""} onChange={ev => this.filter.fileName = ev.target.value} />
                    </ui.Form.Item>
                    <ui.Form.Item>
                        <ui.Select onInputKeyDown={ev => this.checkFilterSubmit(ev)} value={this.filter.type || ""} onChange={v => this.filter.type = v} dropdownMatchSelectWidth={false}>
                            <ui.Select.Option value="">(Filter by type)</ui.Select.Option>
                            {_.sortBy(Object.keys(dtos.FileTypes)).map(t => <ui.Select.Option key={t} value={t}>{splitIntoWords(t)}</ui.Select.Option>)}
                        </ui.Select>
                    </ui.Form.Item>
                    <ui.Form.Item>
                        <AutoComplete type={dtos.ValueTypes.Customer} includeNotAssigned={true} value={this.filter.customerId} onSearch={() => this.refresh()} onChanged={v => this.filter.customerId = v} placeholder="Filter by customer" />
                    </ui.Form.Item>
                    <ui.Form.Item>
                        <AutoComplete type={dtos.ValueTypes.User} value={this.filter.userId} onSearch={() => this.refresh()} onChanged={v => this.filter.userId = v} placeholder="Filter by user" />
                    </ui.Form.Item>
                    <ui.Form.Item>
                        <ui.DatePicker.RangePicker placeholder={["Start of date range", "End of date range"]} format="LL" value={[this.startDate, this.endDate]} onChange={ev => this.setDateRange(ev)} />
                    </ui.Form.Item>
                    <ui.Form.Item>
                        <ui.Input onKeyPress={ev => this.checkFilterSubmit(ev)} placeholder="Search by transcription" value={this.filter.transcriptionContains || ""} onChange={ev => this.filter.transcriptionContains = ev.target.value} />
                    </ui.Form.Item>
                    <ui.Form.Item>
                        <ui.Button.Group>
                            <ui.Button type="primary" onClick={() => this.refresh()}>Search</ui.Button>
                            <ui.Button type="default" onClick={() => this.clearSearch()}>Clear</ui.Button>
                        </ui.Button.Group>
                    </ui.Form.Item>
                </ui.Form>
                <ui.Table className="hide-pagination-buttons" loading={this.loading} rowSelection={rowSelection} dataSource={this.files} rowKey="id" pagination={this.pagination} onChange={(p, f, s) => this.refresh(p.current, s)}>
                    <ui.Table.Column title="File Name" dataIndex="fileName" width={150} sorter={true} render={(text, rec: dtos.FileInfo) =>
                        <div>
                            {!rec.contentType.startsWith("audio/") && <a target="_blank" href={rec.uri}>{(rec.fileName || "(none)").substring(0, 75)}</a>}

                            {rec.contentType.startsWith("audio/") && <><a onClick={() => this.showAudioFile(rec)}>{rec.fileName}</a> <span style={{ marginLeft: 5 }}><small>(Click to listen)</small></span></>}
                        </div>} />
                    <ui.Table.Column title="Type" dataIndex="type" key="type" sorter={true} />
                    <ui.Table.Column title="Date Created" dataIndex="dateCreated" sorter={true} render={(text, rec: dtos.FileInfo) => moment(rec.dateCreated).format("LLLL") + (rec.recordingFrom ? " from " + rec.recordingFrom : "")} />
                    {shared.canViewCustomers() && <ui.Table.Column title="Customer" dataIndex="customerName" render={(text, rec: dtos.FileInfo) => rec.customerId && <Link to={`/customers/${rec.customerId}`}>{rec.customerBreadcrumb.map(bc => bc.name).join(" > ")}</Link>} />}
                    <ui.Table.Column title="User" dataIndex="userName" />
                    <ui.Table.Column title="File Size" dataIndex="contentLength" render={(text, rec: dtos.FileInfo) => humanFileSize(rec.contentLength, true)} />
                    <ui.Table.Column title="Content Type" dataIndex="contentType" />
                    <ui.Table.Column title="Transcription" render={(text, rec: dtos.FileInfo) => <span>{rec.contentType.startsWith("audio/") ? (rec.transcription ? "Yes" : "No") : ""}</span>} />
                    <ui.Table.Column title="" render={(text, rec: dtos.FileInfo) =>
                        <div>
                        </div>} />
                    <ui.Table.Column render={(text, rec: dtos.FileInfo) =>
                        <ui.Button.Group>
                            <ui.Button onClick={() => this.startEditFile(rec)}><i className="fa fa-pencil" /></ui.Button>
                            <ui.Upload showUploadList={false} name="file" data={{ accountId: this.accountId, fileId: rec.id }} withCredentials={true} headers={{ "X-Requested-With": null }} method="PATCH" action={serviceClient.createUrlFromDto("PATCH", new dtos.PatchFile())} onChange={info => this.onPatchChange(info)}>
                                <ui.Button title="Replace File"><i className="fa fa-upload" /></ui.Button>
                            </ui.Upload>
                            <ui.Popconfirm title="Are you sure that you want to delete this file?" onConfirm={() => this.deleteFile(rec)}>
                                <ui.Button danger><i className="fa fa-trash" /></ui.Button>
                            </ui.Popconfirm>
                        </ui.Button.Group>
                    } />
                </ui.Table>

                <ui.Modal forceRender title={this.audioFileToShow ? this.audioFileToShow.fileName : "Audio Player"} visible={this.showAudioFileWindow} okButtonProps={{ hidden: true }} cancelText="Close" onCancel={() => { this.showAudioFileWindow = false; this.audioPlayer.current.pause(); }}>
                    {this.loadingAudioFile && <ui.Spin size="large" />}
                    {this.audioFileToShow && this.audioFileToShow.transcription && <pre style={{ whiteSpace: "pre-wrap" }}>
                        Transcription: <br />
                        {this.audioFileToShow.transcription}
                    </pre>}
                    <div style={{ display: this.loadingAudioFile ? "none" : "block" }}>
                        <audio ref={this.audioPlayer} preload="auto" controls />
                    </div>
                    {!this.playbackOptimized && <p><small>Playback could not be optimized for this file. It may require buffering and prevent seeking.</small></p>}
                </ui.Modal>

                <ui.Modal title="Edit File" visible={this.showUpdateFile} okText="Save" onOk={() => this.saveFile()} onCancel={() => this.showUpdateFile = false}>
                    <ui.Form layout="vertical">
                        <ui.Form.Item label="Customer">
                            <AutoComplete type={dtos.ValueTypes.Customer} value={this.editFileCustomer} onChanged={v => this.editFileCustomer = v} placeholder="Select a customer" />
                        </ui.Form.Item>
                        {this.editFileCustomer && <ui.Form.Item label="User">
                            <AutoComplete type={dtos.ValueTypes.User} customerId={this.editFileCustomer} value={this.editFileUser} onChanged={v => this.editFileUser = v} placeholder="Select a user" />
                        </ui.Form.Item>}
                        <ui.Form.Item label="File Name">
                            <ui.Input value={this.editFileName} onChange={ev => this.editFileName = ev.target.value} />
                        </ui.Form.Item>
                    </ui.Form>
                </ui.Modal>
                <AudioRecorder accountId={this.accountId} visible={this.showVoiceRecorder} onClosed={() => this.showVoiceRecorder = false} onSaved={f => this.onSavedFile(f)} />
            </div>
        );
    }
}